import React from 'react';

function Header() {
  return (
  <header>
    <h1 className="header-name">
      <a href="http://terrychiem.com">
        TERRY CHIEM
      </a>
    </h1>
    <div className="header-webdev">Web Developer</div>
  </header>)
}

export default Header;